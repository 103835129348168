

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonGrid, IonRow, IonCol, IonImg, IonButton, IonInput, IonLabel, IonList, IonSearchbar, IonThumbnail, IonItem } from '@ionic/react';
import React from 'react'
import { useState } from "react";
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Test.css';

import { download, camera, film } from 'ionicons/icons';

import { useForm, Controller } from "react-hook-form";

import { get_server } from '../components/Server'


const Test: React.FC = () => {

    const { name } = useParams<{ name: string; }>();

    const[photo_urls, set_photos] = useState<string[]>([]);
    const[assets, set_assets] = useState<any[]>([]);

    const { control, register, handleSubmit, errors, formState } = useForm();
    const [data, setData] = useState();
    const onSubmit = (data: any) => {
        alert(JSON.stringify(data, null, 2));
        setData(data);
    };


    const [search_text, set_search_text] = useState('');


    // TEST TEST TEST
    const get_photos = async() => {

        const new_photos = [
        "https://media.istockphoto.com/photos/the-sun-goes-down-behind-the-autumn-forest-picture-id1162998855",
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
        "https://media.istockphoto.com/photos/tree-on-grassy-field-against-cloudy-sky-picture-id649725910",
        ... photo_urls
        ];

        set_photos(new_photos);

    }


    const add_photos = async(urls: string[]) => {
        const new_photos = [
        ... urls,
        ... photo_urls
        ];

        set_photos(new_photos);


    }


    const get_asset_info = async () => {

        var server = await get_server();

        if (search_text) {
            var sobjects = server.eval("@SOBJECT(vfx/asset['code','"+search_text+"'])");
        }
        else {
            var sobjects = server.eval("@SOBJECT(vfx/asset)");
        }

        var paths: string[] = [];

        sobjects.forEach( (sobject: object) => {
            var path = server.get_paths(sobject, {process: "icon", path_types: ["web"], single: true});
            if (!path) return;
            paths.push(path.web_path);

        })

        if (paths.length) {
            add_photos(paths);
        }

        set_assets(sobjects)

        return sobjects;
    }

    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonList>
              <IonSearchbar placeholder="Search by Keyword" onIonChange={e => set_search_text(e.detail.value!)} />
          </IonList>

          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => get_asset_info()}>SEARCH</IonButton>
            <input type="submit"/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {
            assets.map( (asset, index) => (
            <IonItem>
                <IonThumbnail slot="start" class="spt_asset_thumbnail">
                  <img src={photo_urls[index]}/>
                </IonThumbnail>
                <IonLabel>
                  <h3>{asset.code}</h3>
                  <p>{asset.description || "N / A"}</p>
                  <p>{photo_urls[index]}</p>

                  <IonButtons slot="end">
                    <IonFabButton color="primary" onClick={() => get_asset_info()}><IonIcon icon={download}></IonIcon></IonFabButton>
                  </IonButtons>
                </IonLabel>


            </IonItem>

            ) )
        }


        <IonGrid>
          <IonRow>
            <IonCol size="6">
              {
                photo_urls.map( (url, index) => (
                  <div>
                    <div>Title</div>
                    <IonImg src={url}/>
                  </div>
                ) )
              }

            </IonCol>
            <IonCol size="6">
              <IonImg src="https://media.istockphoto.com/photos/tree-on-grassy-field-against-cloudy-sky-picture-id649725910"/>
              <IonImg src="https://media.istockphoto.com/photos/the-sun-goes-down-behind-the-autumn-forest-picture-id1162998855"/>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonFab vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton onClick={() => {get_photos();}}>
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton onClick={() => get_asset_info()}>
            <IonIcon icon={film}></IonIcon>
          </IonFabButton>
        </IonFab>

      </IonContent>
    </IonPage>
    );
};

export default Test;
