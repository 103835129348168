import React from 'react'
import { useState } from 'react'

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonRow,
  IonMenuButton,
  IonCol,
  IonAlert,
  IonIcon,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonItemDivider,
  useIonViewWillEnter,
} from "@ionic/react";
import { useHistory } from 'react-router-dom';
import { personCircle } from "ionicons/icons";

import { get_storage, set_storage } from '../components/Storage';
import LogOut from '../components/Logout'

import TaskList      from '../pages/TaskList';

import { TACTIC, spt } from "../assets/js/tactic.js";

const LogIn: React.FC = () => {

    const [email, setEmail] = useState<string>()

    const [password, setPassword] = useState<string>()
    const [server_url, setServer] = useState<string>("https://portal.southpawtech.com")
    const [project, setProject] = useState<string>("cloud_city")
    const [site, setSite] = useState<string>("municipal")

    const [credentials, setCredentials] = useState<any[any]>()

    const [loggedInTicket, setLoggedInTicket] = useState<string>()

    useIonViewWillEnter( () => {

      get_storage("credentials").then((val) => {
        //console.log(val);
        if (val) {
          setServer(val.server_url);
          setEmail(val.email);
          setLoggedInTicket(val.login_ticket);
        }
      });

    });

    const [errorMsg, setErrMsg] = useState<string>()

    const history = useHistory()

    const handleLogin = async () => {
      //console.log(email);
      //console.log(password);

      let server = TACTIC.get();
      server.set_server(server_url);
      server.set_project(project);

      let ticket = '';

      try {
        //ticket = server.get_ticket(email, password,{site: 'workflow'});
        ticket = await server.p_get_ticket(email, password);
        //console.log(ticket + " from get_ticket()");

        let env = spt.Environment.get()
        env.set_ticket(ticket);
        //console.log(env.get_ticket() + "  in login.tsx from env.get_ticket")

        server.set_ticket(ticket);

        setErrMsg('Log in successful.')
        setPassword("")

        const credentials = {
          'server_url': server_url,
          'email': email,
          'login_ticket': ticket,
          'project': project,
          'site': site

        }
        setCredentials(credentials);
        set_storage("credentials", credentials);

        history.push('/page/MyTasks') // redirect to MyTasks page.
      }
      catch(e) {
        //console.log('Error:', e);
        setErrMsg(e);
      }
      //console.log(ticket)
    };

    const isLoggedIn = () => {
      //console.log(loggedInTicket)
      if (loggedInTicket) {
        return(
          <IonRow>
            <IonCol>
              <LogOut />
            </IonCol>
          </IonRow>
        );
      }
      return (
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={handleLogin}>
              Login
            </IonButton>
          </IonCol>
        </IonRow>
      );
    }

    //TODO: not used at the moment. Place after user name and passwd: { serverInfo() }
    const serverInfo = () => {
      if (loggedInTicket) {
        return (null);
      }
      return (
        <><IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating"> Server URL</IonLabel>
              <IonInput
                type="text"
                value={server_url}
                onIonChange={(e) => setServer(e.detail.value!)}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating"> Project</IonLabel>
                <IonInput
                  type="text"
                  value={project}
                  onIonChange={(e) => setProject(e.detail.value!)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating"> Site</IonLabel>
              <IonInput
                type="text"
                value={site}
                onIonChange={(e) => setSite(e.detail.value!) }
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow></>
      );
    }

    const userName = () => {
      if (loggedInTicket) {
        return (null);
      }
      return (
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating"> User Name</IonLabel>
              <IonInput
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
      );
    }

    const userPasswd = () => {
      if (loggedInTicket) {
        return (null);
      }
      return (
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating"> Password</IonLabel>
              <IonInput
                type="password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
      );
    }


    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              <IonTitle>Login</IonTitle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding ion-text-center">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonIcon
                  style={{ fontSize: "70px", color: "#0040ff" }}
                  icon={personCircle}
                />
              </IonCol>
            </IonRow>

            { userName() }

            { userPasswd() }

            { /* serverInfo() */ }

            { isLoggedIn() }

            <IonRow>
              <IonCol>
                <p>{errorMsg}</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
}

export default LogIn;