
import React from 'react'

import ReactDOM from 'react-dom';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonRow,
  IonMenuButton,
  IonCol,
  IonAlert,
  IonIcon,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonButton
} from "@ionic/react";

// @ts-ignore
import { Form, FormBuilder, Formio } from 'react-formio';

import MyComponent from '../components/MyModule';
Formio.use(MyComponent);


const TestForm: React.FC = () => {
  return (
    <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              <IonTitle>Test Form</IonTitle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding ion-text-center">
          <Form
          form={{
            components: [
              {
                "label": "Select the type of Request",
                "widget": "choicesjs",
                "placeholder": "Requet Type",
                "tableView": true,
                "data": {
                  "values": [
                    {
                      "label": "Pickup of household hazardous waste",
                      "value": "pickupOfHouseholdHazardousWaste"
                    },
                    {
                      "label": "A new bin for a resale of home or new development",
                      "value": "new_bin"
                    },
                    {
                      "label": "An additional garbage bin.",
                      "value": "additional_garbage_bin"
                    },
                    {
                      "label": "Exchange size of a garbage bin",
                      "value": "exchange"
                    },
                    {
                      "label": "An additional recycling bin",
                      "value": "additiona_recycle_bin"
                    }
                  ]
                },
                "selectThreshold": 0.3,
                "calculateServer": false,
                "key": "request",
                "type": "select",
                "indexeddb": {
                  "filter": {}
                },
                "input": true
              },
              {
                "title": "To do this request, you need to:",
                "collapsible": false,
                "tableView": false,
                "key": "toDoThisRequestYouNeedTo",
                "type": "panel",
                "label": "Panel",
                "input": false,
                "components": [
                  {
                    "label": "Provide a valid Toronto location",
                    "placeholder": "Location",
                    "spellcheck": true,
                    "tableView": true,
                    "calculateServer": false,
                    "key": "location",
                    "type": "textfield",
                    "input": true
                  },
                  {
                    "label": "Receive Residential Collection?",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "receiveResidentialCollection",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "Select the sizes for you Garbage and Recycling Bins",
                    "widget": "choicesjs",
                    "tableView": true,
                    "data": {
                      "values": [
                        {
                          "label": "Small",
                          "value": "small"
                        },
                        {
                          "label": "Medium",
                          "value": "medium"
                        },
                        {
                          "label": "Large",
                          "value": "large"
                        },
                        {
                          "label": "X-Large",
                          "value": "xlarge"
                        }
                      ]
                    },
                    "selectThreshold": 0.3,
                    "calculateServer": false,
                    "key": "selectTheSizesForYouGarbageAndRecyclingBins",
                    "type": "select",
                    "indexeddb": {
                      "filter": {}
                    },
                    "input": true
                  },
                  {
                    "label": "dual_use_bin",
                    "content": "Call 311 if you need a \"dual-use\" bin.",
                    "refreshOnChange": false,
                    "tableView": false,
                    "key": "dualUseBin",
                    "conditional": {
                      "show": true,
                      "when": "request",
                      "eq": "additional_garbage_bin"
                    },
                    "attributes": {
                      "request": "additional_garbage_bin"
                    },
                    "type": "htmlelement",
                    "input": false
                  },
                  {
                    "label": "additional_garbage_bin",
                    "attrs": [
                      {
                        "attr": "",
                        "value": ""
                      }
                    ],
                    "content": "Call 311 if you need to order more than one additional Garbage Bin",
                    "refreshOnChange": false,
                    "tableView": false,
                    "key": "additionalGarbageBin",
                    "conditional": {
                      "show": true,
                      "when": "request",
                      "eq": "additional_garbage_bin"
                    },
                    "type": "htmlelement",
                    "input": false
                  },
                  {
                    "label": "street_missed",
                    "attrs": [
                      {
                        "attr": "",
                        "value": ""
                      }
                    ],
                    "content": "Call 311 if your whole street was missed.",
                    "refreshOnChange": false,
                    "tableView": false,
                    "key": "streetMissed",
                    "conditional": {
                      "show": true,
                      "when": "request",
                      "eq": "additional_garbage_bin"
                    },
                    "type": "htmlelement",
                    "input": false
                  },
                  {
                    "label": "door_service",
                    "attrs": [
                      {
                        "attr": "",
                        "value": ""
                      }
                    ],
                    "content": "Call 311 if this is for front/side door service.",
                    "refreshOnChange": false,
                    "tableView": false,
                    "key": "doorService",
                    "type": "htmlelement",
                    "input": false
                  },
                  {
                    "label": "Provide a name and phone number",
                    "spellcheck": true,
                    "tableView": true,
                    "calculateServer": false,
                    "key": "provideANameAndPhoneNumber",
                    "type": "textfield",
                    "input": true
                  }
                ]
              },
              {
                "title": "Please Confirm the Following",
                "collapsible": false,
                "tableView": false,
                "key": "pleaseConfirmTheFollowing",
                "type": "panel",
                "label": "Panel",
                "input": false,
                "components": [
                  {
                    "label": "For a multi-residential apartment or condominium building, confirm you are the superintendent, property manager or agent.",
                    "tableView": false,
                    "defaultValue": false,
                    "calculateServer": false,
                    "key": "forAMultiResidentialApartmentOrCondominiumBuildingConfirmYouAreTheSuperintendentPropertyManagerOrAgent",
                    "conditional": {
                      "show": true,
                      "when": "request",
                      "eq": "additional_garbage_bin"
                    },
                    "type": "checkbox",
                    "input": true
                  },
                  {
                    "label": "I have an acceptable amount (between 10 and 50 litres) for Household Hazardous Wast pick-up.",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "iHaveAnAcceptableAmountBetween10And50LitresForHouseholdHazardousWastPickUp",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "All of my items are eligible for (Houshold Hazardous Waste, bulky items) pickup.  Which items are eligible for pick-up? How do I dispose of non-eligible items?",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "allOfMyItemsAreEligibleForHousholdHazardousWasteBulkyItemsPickupWhichItemsAreEligibleForPickUpHowDoIDisposeOfNonEligibleItems",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "I will place the items out safely and properly to be accessible for pick-up. How do I place the items out safely and properly?",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "iWillPlaceTheItemsOutSafelyAndProperlyToBeAccessibleForPickUpHowDoIPlaceTheItemsOutSafelyAndProperly",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "I am reporting items that have not been picked up within 24 hours of the scheduled collection day. Help File: What if it has been more than 24 hours from the collection day?",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "iAmReportingItemsThatHaveNotBeenPickedUpWithin24HoursOfTheScheduledCollectionDayHelpFileWhatIfItHasBeenMoreThan24HoursFromTheCollectionDay",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "My (selection criteria: bulky items , garbage, recycling , green bin, yard waste) were put out on the right day and by the right time",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "mySelectionCriteriaBulkyItemsGarbageRecyclingGreenBinYardWasteWerePutOutOnTheRightDayAndByTheRightTime",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "Items were accessible/visible and placed out properly. ( Help File popup: How do I place out items properly?)",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "itemsWereAccessibleVisibleAndPlacedOutProperlyHelpFilePopupHowDoIPlaceOutItemsProperly",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": " I currently have at least one City-issued Garbage Bin. Help File: What if my current bin is smaller than Extra-Large? How do I know my current bin size? What if my bin is missing (lost or stolen)? How do I get my bin repaired if it is damaged? How do I exchange my bin for one of a different size?",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "iCurrentlyHaveAtLeastOneCityIssuedGarbageBinHelpFileWhatIfMyCurrentBinIsSmallerThanExtraLargeHowDoIKnowMyCurrentBinSizeWhatIfMyBinIsMissingLostOrStolenHowDoIGetMyBinRepairedIfItIsDamagedHowDoIExchangeMyBinForOneOfADifferentSize",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "My name appears on the City utility bill at the address of this request. Help File:What is a City utility bill?",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "myNameAppearsOnTheCityUtilityBillAtTheAddressOfThisRequestHelpFileWhatIsACityUtilityBill",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  },
                  {
                    "label": "I agree to the fee associated with this request. Help File: How much does this cost?",
                    "tableView": false,
                    "calculateServer": false,
                    "key": "iAgreeToTheFeeAssociatedWithThisRequestHelpFileHowMuchDoesThisCost",
                    "type": "checkbox",
                    "input": true,
                    "defaultValue": false
                  }
                ]
              },
              {
                "title": "Service Request Details",
                "collapsible": false,
                "tableView": false,
                "key": "serviceRequestDetails",
                "type": "panel",
                "label": "Panel",
                "input": false,
                "components": [
                  {
                    "label": "HTML",
                    "attrs": [
                      {
                        "attr": "",
                        "value": ""
                      }
                    ],
                    "content": "Please provide any other details about this request.\r\n * = required",
                    "refreshOnChange": false,
                    "tableView": false,
                    "key": "html",
                    "type": "htmlelement",
                    "input": false
                  },
                  {
                    "label": "Text Please specify the type and number of items to be picked up:",
                    "spellcheck": true,
                    "tableView": true,
                    "calculateServer": false,
                    "validate": {
                      "required": true
                    },
                    "key": "textPleaseSpecifyTheTypeAndNumberOfItemsToBePickedUp",
                    "type": "textfield",
                    "input": true
                  },
                  {
                    "label": "Indicate the exact location where the items will be placed:",
                    "spellcheck": true,
                    "tableView": true,
                    "calculateServer": false,
                    "validate": {
                      "required": true
                    },
                    "key": "indicateTheExactLocationWhereTheItemsWillBePlaced",
                    "type": "textfield",
                    "input": true
                  },
                  {
                    "label": "Please enter any additional description/details:",
                    "autoExpand": false,
                    "spellcheck": true,
                    "tableView": true,
                    "calculateServer": false,
                    "key": "pleaseEnterAnyAdditionalDescriptionDetails",
                    "type": "textarea",
                    "input": true
                  },
                  {
                    "label": "When does your (selection criteria: garbage, recycling, green bin, yard waste) normally get collected?",
                    "widget": "choicesjs",
                    "tableView": true,
                    "data": {
                      "values": [
                        {
                          "label": "Day",
                          "value": "day"
                        },
                        {
                          "label": "Night",
                          "value": "night"
                        },
                        {
                          "label": "Not Sure",
                          "value": "notSure"
                        }
                      ]
                    },
                    "selectThreshold": 0.3,
                    "calculateServer": false,
                    "validate": {
                      "required": true
                    },
                    "key": "whenDoesYourSelectionCriteriaGarbageRecyclingGreenBinYardWasteNormallyGetCollected",
                    "type": "select",
                    "indexeddb": {
                      "filter": {}
                    },
                    "input": true
                  }
                ]
              },
              {
                "type": "button",
                "label": "Submit",
                "key": "submit",
                "disableOnInvalid": true,
                "input": true,
                "tableView": false
              }
            ],
            display: "form"
          }}
        />
      </IonContent>
    </IonPage>

  );
}

export default TestForm;

