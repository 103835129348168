import {} from '@ionic/react';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
const set_storage = async (key: string, data: any) => {
    let item = {
        key: key,
        value: JSON.stringify(data)
    }
    await Storage.set(item);
}
const get_storage = async (key: string) => {
    const ret_str: any = await Storage.get({key: key});
    let value = ret_str.value;
    let ret: any;
    try {
        ret = JSON.parse(value);
    }
    catch(e) {
        return null;
    }

    return ret;
}

const remove_storage = async (key:string) => {
    await Storage.remove({ key: key });
}

export { get_storage, set_storage, remove_storage };

