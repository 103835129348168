import React from 'react'
import { useState } from 'react'

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonRow,
  IonMenuButton,
  IonCol,
  IonAlert,
  IonIcon,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonItemDivider,
  useIonViewWillEnter
} from "@ionic/react";
import { useHistory } from 'react-router-dom';
import { informationCircle } from "ionicons/icons";

import { get_storage, set_storage } from '../components/Storage';

import { TACTIC, spt } from "../assets/js/tactic.js";

import { get_server, p_server_cmd } from '../components/Server'

const Profile: React.FC = () => {

    const history = useHistory();

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              <IonTitle>About</IonTitle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding ion-text-center">
          <p>Version 1.0.1</p>
        </IonContent>
      </IonPage>
    );
}

export default Profile;
