import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  cubeOutline,
  constructOutline,
  fileTrayOutline,
  addCircleOutline,
  logInOutline,
  mapOutline,
  personCircleOutline,
  informationCircleOutline
} from "ionicons/icons";
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'New Request',
    url: '/page/JobTypeSelect',
    iosIcon: addCircleOutline,
    mdIcon: addCircleOutline
  },
  {
    title: 'Log In',
    url: '/page/LogIn',
    iosIcon: logInOutline,
    mdIcon: logInOutline
  },
  {
    title: 'Register',
    url: '/page/Register',
    iosIcon: personCircleOutline,
    mdIcon: personCircleOutline
  },
  {
    title: 'My Tasks',
    url: '/page/MyTasks',
    iosIcon: fileTrayOutline,
    mdIcon: fileTrayOutline
  },
  {
    title: 'Jobs',
    url: '/page/JobList',
    iosIcon: constructOutline,
    mdIcon: constructOutline
  },

  {
    title: 'Asset Library',
    url: '/page/AssetList',
    iosIcon: cubeOutline,
    mdIcon: cubeOutline
  },
  /*
  {
    title: 'TestForm',
    url: '/page/TestForm',
    iosIcon: filmOutline,
    mdIcon: filmOutline
  },
  */

  {
    title: 'Profile',
    url: '/page/Profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleOutline
  },

  {
    title: 'Map',
    url: '/page/Map',
    iosIcon: mapOutline,
    mdIcon: mapOutline
  },

  {
    title: 'About',
    url: '/page/About',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleOutline
  },

];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>TACTIC</IonListHeader>
          <IonNote>Workflow Solution</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
