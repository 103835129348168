

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonGrid, IonRow, IonCol, IonImg, IonButton, IonInput, IonLabel,
    IonList,
    IonSearchbar,
    IonThumbnail,
    IonItem,
    useIonViewWillEnter,
} from '@ionic/react';
import React from 'react'
import { useState } from "react";
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import './AssetList.css';

import { cloudUploadOutline, cloudDownloadOutline, camera, film } from 'ionicons/icons';

import { get_server } from '../components/Server'

const AssetList: React.FC = () => {

    const { name } = useParams<{ name: string; }>();


    useIonViewWillEnter( () => {
        get_asset_info();
    } );


    const[asset_urls, set_asset_urls] = useState<string[]>([]);
    const[assets, set_assets] = useState<any[]>([]);

    const [search_text, set_search_text] = useState('');


    const get_asset_info = async () => {

        let server = await get_server();

        let cmd = "spt.modules.workflow.SearchAssetCmd";
        let kwargs = {
            keywords: search_text
        }
        var ret_val = server.p_execute_cmd(cmd, kwargs)
        var info = ret_val.info;
        if (typeof info == 'undefined') {
          return;
        }
        var assets = info.sobjects;

        set_assets(assets)
        /*
        server.p_execute_cmd(cmd)
        .then( (ret_val: any) => {
            var info = ret_val.info;
            var assets = info.sobjects;
            console.log(assets);
        } );
        */
        return;
    }

    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonList>
              <IonSearchbar placeholder="Search by Keyword" onIonChange={e => set_search_text(e.detail.value!)} />
          </IonList>

          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => get_asset_info()}>SEARCH</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {
            assets.map( (asset, index) => (
            <IonItem>
                <IonThumbnail slot="start" class="spt_asset_thumbnail">
                  <img src={asset.icon_url}/>
                </IonThumbnail>
                <IonLabel>
                  <Link to={"/page/AssetDetail/"+asset.code}>{asset.code}</Link>
                  <p>{asset.name || "N / A"}</p>
                </IonLabel>

                <IonButtons slot="end">
                  <IonButton color="primary" onClick={() => get_asset_info()}><IonIcon icon={cloudDownloadOutline}></IonIcon></IonButton>
                  <IonButton color="primary" onClick={() => get_asset_info()}><IonIcon icon={cloudUploadOutline}></IonIcon></IonButton>
                </IonButtons>

            </IonItem>

            ) )
        }

      </IonContent>
    </IonPage>
    );
};

export default AssetList;
