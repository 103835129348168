import React from 'react'
import { useState } from 'react'

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonRow,
  IonMenuButton,
  IonCol,
  IonAlert,
  IonIcon,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonItemDivider,
  useIonViewWillEnter
} from "@ionic/react";
import { useHistory } from 'react-router-dom';
import { personCircle } from "ionicons/icons";

import { get_storage, set_storage } from '../components/Storage';
import LogOut from '../components/Logout'

import TaskList      from '../pages/TaskList';

import { TACTIC, spt } from "../assets/js/tactic.js";

import { get_server, p_server_cmd } from '../components/Server'

const Register: React.FC = () => {

    const data: any = {}
    const login_data: any = {}
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    login_data['project_code'] = 'workflow';

    const history = useHistory()

    const handleSubmit = async () => {

        let server = await get_server();

        console.log(login_data);
        console.log(data);

        let password = login_data['password']
        let password2 = login_data['password2']
        if (password !== password2) {
          setIserror(true);
          setMessage("Passwords don't match!");
          return;
        }


        try {
          server.p_execute_cmd("spt.modules.workflow.RegisterUserCmd", {login_data:login_data, data: data })
        }
        catch(e) {
          //console.log('Error:', e);
          setIserror(true);
          setMessage(e);
        }

    }


    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              <IonTitle>Register</IonTitle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding ion-text-center">
          <IonGrid>

            <IonRow>
              <IonCol>
                <IonAlert
                  isOpen={iserror}
                  onDidDismiss={() => setIserror(false)}
                  header={"Error!"}
                  message={message}
                  buttons={["Dismiss"]}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonIcon
                  style={{ fontSize: "70px", color: "#0040ff" }}
                  icon={personCircle}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">First Name</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (login_data.first_name = e.detail.value)
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Last Name</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (login_data.last_name = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (login_data["email_address"] = e.detail.value)
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">
                    Phone number (strongly recommended)
                  </IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (data["phone_number"] = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Address</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (data["address"] = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Province/State</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (data["state"] = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Postal/Zip Code</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (data["postal_code"] = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Country</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (data["country"] = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonItemDivider color="secondary">
              <IonLabel>Login Info</IonLabel>
            </IonItemDivider>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Login</IonLabel>
                  <IonInput
                    type="text"
                    onIonChange={(e) => (login_data["login"] = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating"> Password</IonLabel>
                  <IonInput
                    type="password"
                    onIonChange={(e) => (login_data["password"] = e.detail.value)
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Confirm Password</IonLabel>
                  <IonInput
                    type="password"
                    onIonChange={(e) => (login_data["password2"] = e.detail.value)
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={handleSubmit}>
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
}

export default Register;
