import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle,
    IonToolbar,
    IonLabel,
    IonButton,

    IonGrid,
    IonRow,
    IonCol,

    IonItem,

    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,

    useIonViewWillEnter,

} from '@ionic/react';

import React from 'react';
import { useState } from "react";
import { useParams } from 'react-router';

import './Page.css';

import { useForm, Controller } from "react-hook-form";

import { get_server } from '../components/Server'


const JobTypeSelect: React.FC = () => {

    const { name } = useParams<{ name: string; }>();

    const [job_types, set_job_types] = useState<any[]>([]);

    useIonViewWillEnter( () => {
        get_job_types();
    } );


    const get_job_types = async () => {
        var server = await get_server();
        var job_types = server.eval("@SOBJECT(workflow/job_type['@ORDER_BY','name'])");
        set_job_types(job_types);
        return;
    }


    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create a new Request</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
          {
            job_types.map( (job_type, index) => {
                return (
                <IonCol size="6">
                  <IonCard>
                    <IonItem lines="none" detail={false}>
                      <a href={"/page/JobNew/"+job_type.code}><h3>{job_type.name}</h3></a>
                    </IonItem>
                    <IonCardHeader>
                      <IonCardSubtitle>{job_type.description}</IonCardSubtitle>
                    </IonCardHeader>
                  </IonCard>
                </IonCol>
                )
            } )
          }
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
    );
};

export default JobTypeSelect;
