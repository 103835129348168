import { Components } from 'formiojs';

//import editForm from 'formiojs/components/table/Table.form'

const FieldComponent = (Components as any).components.field;



/**
 *
 * @param component
 * @param options
 * @param data
 * @constructor
 */
export default class MyComponent extends (FieldComponent as any) {
}

