import React from 'react'
import { useState } from 'react'

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonRow,
  IonMenuButton,
  IonCol,
  IonAlert,
  IonIcon,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonItemDivider,
  useIonViewWillEnter
} from "@ionic/react";
import { useHistory } from 'react-router-dom';
import { personCircle } from "ionicons/icons";

import { get_storage, set_storage } from '../components/Storage';
import LogOut from '../components/Logout'

import TaskList      from '../pages/TaskList';

import { TACTIC, spt } from "../assets/js/tactic.js";

import { get_server, p_server_cmd } from '../components/Server'

const Profile: React.FC = () => {

    //const data: any = {}
    //const login_data: any = {}
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const [user, setUser] = useState<any[any]>();

    const history = useHistory();

    useIonViewWillEnter( () => {
      get_user_info();
    });

    const get_user_info = async () => {
      let server = await get_server()

      let credentials = await get_storage("credentials")
      let email = credentials.email;

      //console.log("user name:", email);
      let cmd = "spt.modules.workflow.GetUserCmd";
      let kwargs = {
          login: email
      }
      var ret_val = await p_server_cmd(cmd, kwargs);
      var info = ret_val.info;
      var user = info.login

      if (!user.data) {
        user.data = {};
      }
      setUser(user);
      //login_data['project_code'] = credentials.project;
    }

    //console.log("user:", user);

    const handleSubmit = async () => {

      let server = await get_server();

      try {
        //console.log(user);
        server.p_execute_cmd("spt.modules.workflow.UpdateUserCmd", { data: user })
      }
      catch(e) {
        //console.log('Error:', e);
        setIserror(true);
        setMessage(e);
      }

  }

    const userInfoDisplay = () => {
      if (!user) { return (null); }
      //return( null );
      // TODO: submit the info to save.
      return (
        <IonGrid>
            <IonRow>
              <IonCol>
                <IonAlert
                  isOpen={iserror}
                  onDidDismiss={() => setIserror(false)}
                  header={"Error!"}
                  message={message}
                  buttons={["Dismiss"]}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonIcon
                  style={{ fontSize: "70px", color: "#0040ff" }}
                  icon={personCircle}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">First Name</IonLabel>
                  <IonInput
                    type="text"
                    value={user.first_name}
                    onIonChange={(e) => (user.first_name = e.detail.value)
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Last Name</IonLabel>
                  <IonInput
                    type="text"
                    value={user.last_name}
                    onIonChange={(e) => (user.last_name = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput
                    type="text"
                    value={user.email}
                    onIonChange={(e) => (user.email = e.detail.value)
                    }
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">
                    Phone number
                  </IonLabel>
                  <IonInput
                    type="text"
                    value={user.data.phone_number}
                    onIonChange={(e) => (user.data.phone_number = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Address</IonLabel>
                  <IonInput
                    type="text"
                    value={user.data.address}
                    onIonChange={(e) => (user.data.address = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Province/State</IonLabel>
                  <IonInput
                    type="text"
                    value={user.data.state}
                    onIonChange={(e) => (user.data.state = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Postal/Zip Code</IonLabel>
                  <IonInput
                    type="text"
                    value={user.data.postal_code}
                    onIonChange={(e) => (user.data.postal_code = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Country</IonLabel>
                  <IonInput
                    type="text"
                    value={user.data.country}
                    onIonChange={(e) => (user.data.country = e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={handleSubmit}>
                  Update
                </IonButton>
              </IonCol>
            </IonRow>

          </IonGrid>
      );

    }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              <IonTitle>Profile</IonTitle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding ion-text-center">
          { userInfoDisplay() }
        </IonContent>
      </IonPage>
    );
}

export default Profile;
