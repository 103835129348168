

import { IonPage, IonHeader, IonSearchbar, IonContent, IonToolbar, IonList, IonButtons, IonButton, IonMenuButton, IonTitle, IonItem, IonThumbnail, IonLabel, IonIcon,
    useIonViewWillEnter,
    IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react'
import { useState } from "react";
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import './TaskList.css';

import { cloudUploadOutline, cloudDownloadOutline, camera, film } from 'ionicons/icons';

import { get_server, p_server_cmd } from '../components/Server'


const TaskList: React.FC = () => {

    const { name } = useParams<{ name: string; }>();

    const [search_text, set_search_text] = useState('');

    const [tasks, set_tasks] = useState<any[]>([]);

    const history = useHistory()

    useIonViewWillEnter( () => {
        get_task_info();
    } );


    const statuses: string[] = ['Assignment','Pending','In Progress', 'Need Assistance','Reject','Complete','Approved'];


    const get_task_info = async () => {

        let cmd = "spt.modules.workflow.GetMyTasksInfoCmd";
        let kwargs = {}
        var ret_val = await p_server_cmd(cmd, kwargs);
        if (!ret_val) {
          history.push('/page/LogIn');
          return;
        }
        var info = ret_val.info;
        var tasks = info.sobjects;


        set_tasks(tasks)
        /*
        server.p_execute_cmd(cmd)
        .then( (ret_val: any) => {
            var info = ret_val.info;
            var tasks = info.sobjects;
            console.log(tasks);
        } );
        */
        return;
    }


    const set_task_status = async (task: any, status: string) => {
        //let server = await get_server();
        let cmd = "spt.modules.workflow.ChangeTaskStatusCmd";
        let kwargs = {
            search_key: task.__search_key__,
            status: status
        }

        var ret_val = await p_server_cmd(cmd, kwargs);


        return;
    }



    // Functional components
    const get_status_select = (task: any) => {
        return (
          <IonSelect placeholder="Select Status" interface="popover" name="process" value={task.status} onIonChange={e => set_task_status(task, e.detail.value)}>
          {
            statuses.map( (status, index) => {
              return (
              <IonSelectOption key={index} placeholder="Select Status" value={status}>{status}</IonSelectOption>
              )
            } )
          }
          </IonSelect>
        );
    }



    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>My Tasks</IonTitle>

          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => get_task_info()}>SEARCH</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>


        {
            tasks.map( (task, index) => (
            <IonItem key={index}>
                <IonLabel>
                  <IonLabel><a href={"/page/TaskDetail/"+task.code}>{task.search_code}</a></IonLabel>
                  <p>PROCESS: {task.process}</p>
                </IonLabel>

                <IonLabel>
                  {get_status_select(task)}
                </IonLabel>

                <IonButtons slot="end">
                  <IonButton color="primary" onClick={() => get_task_info()}><IonIcon icon={cloudDownloadOutline}></IonIcon></IonButton>
                  <IonButton color="primary" onClick={() => get_task_info()}><IonIcon icon={cloudUploadOutline}></IonIcon></IonButton>
                </IonButtons>


            </IonItem>

            ) )
        }

      </IonContent>
    </IonPage>
    );
};

export default TaskList;
