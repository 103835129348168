import React from 'react'
import { useState } from 'react'

import { IonButton, useIonViewWillEnter } from "@ionic/react";
import { useHistory } from 'react-router-dom';

import { get_storage, set_storage, remove_storage } from '../components/Storage';
import { get_server, p_server_cmd } from '../components/Server'

import { TACTIC, spt } from "../assets/js/tactic.js";

const LogOut: React.FC = () => {

    const [email, setEmail] = useState<string>()
    const [server_url, setServer] = useState<string>()

    const history = useHistory()

    const handleLogout = async () => {
        let server = TACTIC.get();

        let storage_data = await get_storage("credentials");


        let server_url = storage_data.server_url;
        let email = storage_data.email;
        setServer(server_url);
        setEmail(email);


        server.set_server(server_url);
        try {

            //server.p_execute_cmd("SignOutCmd", {login: email })
            server.set_ticket("");

            remove_storage("credentials");

            let env = spt.Environment.get()
            env.set_user("")
            env.set_ticket("");

            //history.push("/page/Login")
            //TODO: This is just workaround to refresh the /page/Login page.
            history.push("/page/MyTasks")

        } catch(e) {
            console.log("Server:", e)
        }


    };
    return (
        <IonButton expand="block" onClick={handleLogout}>Log Out</IonButton>
    );
}

export default LogOut;