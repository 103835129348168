import Menu from './components/Menu';
import Page from './pages/Page';

import AssetList     from './pages/AssetList';
import AssetDetail   from './pages/AssetDetail';
import JobNew        from './pages/JobNew';
import JobTypeSelect from './pages/JobTypeSelect';
import JobList       from './pages/JobList';
import JobDetail     from './pages/JobDetail';
import TaskList      from './pages/TaskList';
import Test          from './pages/Test';
import LogIn         from './pages/LogIn';
import Register      from './pages/Register';
import TestForm      from './pages/TestForm';
import Profile       from './pages/Profile';
import Map           from './pages/Map';
import About         from './pages/About';


import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/page/:name" component={Page} exact />
            <Route path="/page/LogIn" component={LogIn} exact />
            <Route path="/page/Register" component={Register} exact />
            <Route path="/page/AssetList" component={AssetList} exact />
            <Route path="/page/AssetDetail/:asset_code" component={AssetDetail} exact />
            <Route path="/page/JobNew" component={JobNew} exact />
            <Route path="/page/JobNew/:job_type_code" component={JobNew} exact />
            <Route path="/page/JobEdit/:job_code" component={JobNew} exact />
            <Route path="/page/JobTypeSelect" component={JobTypeSelect} exact />
            <Route path="/page/JobList" component={JobList} exact />
            <Route path="/page/JobDetail/:job_code" component={JobDetail} exact />
            <Route path="/page/MyTasks" component={TaskList} exact />
            <Route path="/page/Test" component={Test} exact />
            <Route path="/page/TestForm" component={TestForm} exact />
            <Route path="/page/Profile" component={Profile} exact />
            <Route path="/page/Map" component={Map} exact />
            <Route path="/page/About" component={About} exact />
            <Redirect from="/" to="/page/LogIn" exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
