import React from 'react'
import { useState } from 'react'

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonRow,
  IonMenuButton,
  IonCol,
  IonAlert,
  IonIcon,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonItemDivider
} from "@ionic/react";
import { useHistory } from 'react-router-dom';
import { personCircle } from "ionicons/icons";

import { Plugins} from "@capacitor/core";

import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder';

import { get_storage, set_storage } from '../components/Storage';
import LogOut from '../components/Logout'

import TaskList      from '../pages/TaskList';

import { TACTIC, spt } from "../assets/js/tactic.js";

import { get_server } from '../components/Server';

import { TileLayer, Marker, Popup } from 'react-leaflet';
import { MapContainer } from 'react-leaflet';

import Leaflet from 'leaflet';

import 'leaflet/dist/leaflet.css';
import './map.css'

//Leaflet.Icon.Default.imagePath = '../node_modules/leaflet/'
                    // NOTE: I am not sure why this doesn't work. So I did the following.
Leaflet.Icon.Default.imagePath = '/leaflet/'; //markmark
                    // I had to copy node_modules/leaflet/dist/images to public/leaflet to make this work.

const Map: React.FC = () => {

    const [showMap, setMap] = useState<boolean>(false);

    const [currentCoord, setLocation] = useState({
      lat: 0.0,
      lon: 0.0
    })

    //let lat: number = 51.505;
    //let lon: number = -0.09;
    //let lat: number = 43.6837599;
    //let lon: number = -79.3465447;

    const { Geolocation, NativeGeocoder} = Plugins;




    const history = useHistory()

    let server = get_server()

    const showLocation = () => {
      const getCurrentLocation = async() => {
        const location = await Geolocation.getCurrentPosition();
        return location;
      }
      getCurrentLocation().then(
        value => {
          let lat = value.coords.latitude;
          let lon = value.coords.longitude;
          console.log(lat, lon, new Date());
          setLocation({ 'lat': lat, 'lon': lon});

          // setting the showMap boolean to render the map in the view.
          setMap(true);

          const nativeGeocoder = async () => {
            const address = await NativeGeocoder.reverseGeocode(lat,lon);
            //console.log(address);
            return address;
          };
          //nativeGeocoder().then(value => console.log(value)).catch(e => console.log("error:", e))

        }
      )
      .catch(e => console.log(e))
    }

    const get_location = () => {
      if (showMap == false) return(null);
      return (
        <MapContainer
            //center={[51.505, -0.09]}
            center={[currentCoord.lat, currentCoord.lon]}
            zoom={13}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[currentCoord.lat, currentCoord.lon]}>
              <Popup>
                You are here.
              </Popup>
            </Marker>
          </MapContainer>
      )
    }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
              <IonTitle>Map</IonTitle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding ion-text-center">

          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={showLocation}>Use my location</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

          { get_location() }

        </IonContent>
      </IonPage>
    );
}

export default Map;
