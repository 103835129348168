import React from 'react';
import { useState } from 'react'
import { useIonViewWillEnter } from "@ionic/react";
import { } from 'ionicons/icons';


import { TACTIC, spt } from "../assets/js/tactic.js";

import { get_storage, set_storage } from './Storage';

const get_server = async () => {
    let server = TACTIC.get();

    let storage_data = await get_storage("credentials");

    if (storage_data == null) {
        //TODO: what to do if it's not loggged in?
        document.location.href = "/page/LogIn";
        return;
    }

    let server_url = storage_data.server_url;
    let project = storage_data.project;
    let ticket = storage_data.login_ticket;
    let site = storage_data.site;

    //console.log(ticket + " ticket");
    //console.log(server);
    //console.log(project);

    let env = spt.Environment.get()
    env.set_ticket(ticket);

    server.set_server(server_url);

    server.set_site(site)

    server.set_project(project);
    server.set_ticket(ticket);
    return server;
}


const p_server_cmd = async( cmd: string, kwargs: any) => {
    let server = await get_server();
    if (!server) {
        return;
    }
    return server.p_execute_cmd(cmd, kwargs);
}

export { get_server, p_server_cmd };

