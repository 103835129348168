

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonBackButton, IonPage,
    IonList,
    IonItem,
    IonAvatar,

    IonTitle,
    IonToolbar, IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
    IonImg,
    IonLabel,
    IonBadge,

    IonSelect, IonSelectOption,

    IonGrid,
    IonCol,
    IonRow,

    IonTabs,
    IonTabBar,
    IonTabButton,

    IonIcon,


    useIonViewWillEnter} from '@ionic/react';

import { IonModal } from '@ionic/react';

import React from 'react'
import { useState } from "react";
import { useParams } from 'react-router';

import JobNew from './JobNew';

import './JobDetail.css';

import { cubeOutline, calendar, personCircle } from 'ionicons/icons';

import { get_server } from '../components/Server'

const JobDetail: React.FC = () => {

    const { name } = useParams<{ name: string; }>();
    const { job_code } = useParams<{ job_code: string; }>();

    const[job, set_job] = useState<any>({});
    const[job_assets, set_job_assets] = useState<any[]>([]);
    const[job_notes, set_job_notes] = useState<any[]>([]);
    const[job_tasks, set_job_tasks] = useState<any[]>([]);

    const[show_modal, set_show_modal] = useState<boolean>(false);
    const[show_upload_modal, set_show_upload_modal] = useState<boolean>(false);


    useIonViewWillEnter( () => {
        get_job_info(job_code);
    } );


    const get_job_info = async (job_code: string) => {

        let server = await get_server();

        let cmd = "spt.modules.workflow.GetJobsInfoCmd";
        let kwargs = {
            job_code: job_code
        }
        let ret_val = server.execute_cmd(cmd, kwargs)
        let info = ret_val.info;
        let job = info.sobjects[0];

        set_job(job)

        let cmd2 = "spt.modules.workflow.GetJobAssetsCmd";
        let kwargs2 = {
            job_code: job_code
        }
        let ret_val2 = server.execute_cmd(cmd2, kwargs2);
        let info2 = ret_val2.info;
        let snapshots = info2.sobjects;

        set_job_assets(snapshots)


        let cmd3 = "spt.modules.workflow.GetNotesInfoCmd";
        let kwargs3 = {
            code: job_code
        };
        let ret_val3 = server.execute_cmd(cmd3, kwargs3);
        let info3 = ret_val3.info;
        let notes = info3.sobjects;

        set_job_notes(notes)


        let cmd4 = "spt.modules.workflow.GetJobTasksCmd";
        let kwargs4 = {
            job_code: job_code
        };
        let ret_val4 = server.execute_cmd(cmd4, kwargs4);
        let info4 = ret_val4.info;
        let tasks = info4.sobjects;

        set_job_tasks(tasks)





        /*
        server.p_execute_cmd(cmd)
        .then( (ret_val: any) => {
            var info = ret_val.info;
            var jobs = info.sobjects;
            console.log(jobs);
        } );
        */
        return;

    }


    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>

          <IonTitle>{job.code} - {job.name}</IonTitle>

            <IonItem slot="end">
                <IonLabel position="stacked">STATUS</IonLabel>
                <IonSelect interface="popover" placeholder="Select" value={job.status}>
                  <IonSelectOption>Pending</IonSelectOption>
                  <IonSelectOption>In Progress</IonSelectOption>
                  <IonSelectOption>Review</IonSelectOption>
                  <IonSelectOption>Complete</IonSelectOption>
                </IonSelect>
            </IonItem>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        {/* Tabs */}
        {/*
        <IonTabs>
          <IonTabBar slot="bottom">
            <IonTabButton tab="Assets" href="/page/Whatever">
              <IonIcon icon={calendar}/>
              <IonLabel>ASSETS</IonLabel>
              <IonBadge>{job_assets.length}</IonBadge>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
        */}


        {/* Add a note */}
        <IonModal isOpen={show_modal}>
          <IonCard>
            <IonCardHeader>
              <p>{job.code} - {job.name}</p>
              <img src={job.icon_url}/>
            </IonCardHeader>
            <IonCardContent>
            <p>Note</p>
            <textarea name="note"> </textarea>
            <IonButton onClick={() => set_show_modal(false)}>Add Note</IonButton>
            </IonCardContent>
          </IonCard>
          <IonButton onClick={() => set_show_modal(false)}>Close</IonButton>
        </IonModal>


        {/* Main Content */}
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <img src={job.url}/>
          <IonCardHeader>
            <IonCardSubtitle>{job.code}</IonCardSubtitle>
            <IonCardTitle>{job.name}</IonCardTitle>
            <IonCardSubtitle>{job.description}</IonCardSubtitle>
            <IonCardSubtitle>STATUS: {job.status}</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
          </IonCardContent>
        </IonCard>


        

        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>
              <IonItem>
                <IonLabel>TASKS</IonLabel>
                <IonBadge color="dark">{job_tasks.length}</IonBadge>
              </IonItem>
             </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent> 
          <IonList>
            {
              job_tasks.map( (job_note, index) => {
                job_note.icon_url = "http://192.168.115.156/assets/workflow/assets/workflow/login/admin/publish/newman-jurassic-park-database-300x280_icon_publish_newman-jurassic-park-database-300x280.png_v001.png";
                return (
                  <IonItem>
                      <IonAvatar slot="start">
                        <img src={job_note.icon_url}/>
                      </IonAvatar>
                      <IonLabel>
                        <h3>{job_note.process}</h3>
                        <h2>{job_note.assigned}</h2>
                        <p>{job_note.timestamp}</p>
                        <p>{job_note.description}</p>
                      </IonLabel>
                      <IonItem slot="end">
                        <IonLabel position="stacked">STATUS</IonLabel>
                        <h3>{job_note.status}</h3>
                      </IonItem>
 
                  </IonItem>
                )
              } )
            }
            </IonList>
            </IonCardContent>

        </IonCard>





        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>
            <IonItem>
              <IonLabel>ASSETS</IonLabel>
              <IonBadge color="dark">{job_assets.length}</IonBadge>
            </IonItem>
            </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent> 
              <IonGrid>
              <IonRow> {
              job_assets.map( (job_asset, index) => {
                return (
                  <IonCol size="3">
                      <IonImg alt="No Image" src={job_asset.icon_url}/>
                      <div>{job_asset.search_code}</div>
                      <div>{job_asset.process}</div>
                  </IonCol>
                )
              } )
              } </IonRow>
              </IonGrid>
            </IonCardContent>

          <IonCardContent>
          </IonCardContent>
        </IonCard>



        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>
              <IonItem>
                <IonLabel>NOTES</IonLabel>
                <IonBadge color="dark">{job_notes.length}</IonBadge>
              </IonItem>
            </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent> 
            <IonList>
            {
              job_notes.map( (job_note, index) => {
                job_note.icon_url = "http://192.168.115.156/assets/workflow/assets/workflow/login/admin/publish/newman-jurassic-park-database-300x280_icon_publish_newman-jurassic-park-database-300x280.png_v001.png";
                return (
                  <IonItem>
                      <IonAvatar slot="start">
                        <img src={job_note.icon_url}/>
                      </IonAvatar>
                      <IonLabel>
                        <h3>{job_note.process}</h3>
                        <h2>{job_note.login}</h2>
                        <p>{job_note.timestamp}</p>
                        <p>{job_note.note}</p>
                      </IonLabel>
                  </IonItem>
                )
              } )
            }
            </IonList>
            </IonCardContent>

        </IonCard>


      </IonContent>
    </IonPage>
    );
};

export default JobDetail;
