

import { IonPage, IonHeader, IonSearchbar, IonContent, IonToolbar, IonList, IonButtons, IonButton, IonMenuButton, IonTitle, IonItem, IonThumbnail, IonLabel,
    IonIcon,
    useIonViewWillEnter
} from '@ionic/react';
import React from 'react'
import { useState } from "react";
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { get_storage, set_storage } from '../components/Storage';
import { TACTIC, spt } from "../assets/js/tactic.js";

import './JobList.css';

import { cloudUploadOutline, cloudDownloadOutline, camera, film } from 'ionicons/icons';

import { get_server, p_server_cmd } from '../components/Server'


const JobList: React.FC = () => {

    const { name } = useParams<{ name: string; }>();

    const [search_text, set_search_text] = useState('');

    const [jobs, set_jobs] = useState<any[]>([]);

    const history = useHistory()

    useIonViewWillEnter( () => {
        get_job_info();
    } );


    const get_job_info = async () => {

        let cmd = "spt.modules.workflow.SearchJobCmd";
        let kwargs = {
            keywords: search_text
        }
        var ret_val = await p_server_cmd(cmd, kwargs);
        if (!ret_val) {
          history.push('/page/LogIn');
          return;
        }
        var info = ret_val.info;
        var jobs = info.sobjects;


        set_jobs(jobs)
        /*
        server.p_execute_cmd(cmd)
        .then( (ret_val: any) => {
            var info = ret_val.info;
            var jobs = info.sobjects;
            console.log(jobs);
        } );
        */
        return;
    }

    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonList>
              <IonSearchbar placeholder="Search by Keyword" onIonChange={e => set_search_text(e.detail.value!)} />
          </IonList>

          <IonButtons slot="start">
            <IonMenuButton />
            <IonTitle>Job List</IonTitle>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => get_job_info()}>SEARCH</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {
            jobs.map( (job, index) => (
            <IonItem key={index}>
                <IonLabel>
                  <Link to={"/page/JobDetail/"+job.code}>{job.code}</Link>
                  <p>{job.name || "N / A"}</p>
                  <p>STATUS: {job.status}</p>
                </IonLabel>

                <IonButtons slot="end">
                  <IonButton color="primary" onClick={() => get_job_info()}><IonIcon icon={cloudDownloadOutline}></IonIcon></IonButton>
                  <IonButton color="primary" onClick={() => get_job_info()}><IonIcon icon={cloudUploadOutline}></IonIcon></IonButton>
                </IonButtons>


            </IonItem>

            ) )
        }

      </IonContent>
    </IonPage>
    );
};

export default JobList;
