

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonBackButton, IonPage, IonTitle, IonToolbar, IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, useIonViewWillEnter} from '@ionic/react';

import { IonModal } from '@ionic/react';

import React from 'react'
import { useState } from "react";
import { useParams } from 'react-router';

import './AssetDetail.css';

import { } from 'ionicons/icons';

import { get_server } from '../components/Server'


const AssetDetail: React.FC = () => {

    const { name } = useParams<{ name: string; }>();
    const { asset_code } = useParams<{ asset_code: string; }>();

    const[asset, set_asset] = useState<any>({});

    const[show_modal, set_show_modal] = useState<boolean>(false);
    const[show_upload_modal, set_show_upload_modal] = useState<boolean>(false);


    useIonViewWillEnter( () => {
        get_asset_info(asset_code);
    } );


    const get_asset_info = async (asset_code: string) => {

        let server = await get_server();

        let cmd = "spt.modules.workflow.GetAssetInfoCmd";
        let kwargs = {
            asset_code: asset_code
        }
        var ret_val = server.p_execute_cmd(cmd, kwargs)
        var info = ret_val.info;
        var asset = info.sobjects[0];

        set_asset(asset)
        console.log(asset);
        /*
        server.p_execute_cmd(cmd)
        .then( (ret_val: any) => {
            var info = ret_val.info;
            var assets = info.sobjects;
            console.log(assets);
        } );
        */
        return;

    }


    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => set_show_modal(true)}>Add Note</IonButton>
            <IonButton color="primary" onClick={() => set_show_upload_modal(true)}>Versions</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        {/* Add a note */}
        <IonModal isOpen={show_modal}>
          <IonCard>
            <IonCardHeader>
              <p>{asset.code} - {asset.name}</p>
              <img src={asset.icon_url}/>
            </IonCardHeader>
            <IonCardContent>
            <p>Note</p>
            <textarea name="note"> </textarea>
            <IonButton onClick={() => set_show_modal(false)}>Add Note</IonButton>
            </IonCardContent>
          </IonCard>
          <IonButton onClick={() => set_show_modal(false)}>Close</IonButton>
        </IonModal>



        {/* History */}
        <IonModal isOpen={show_upload_modal}>
          <IonCard>
            <IonCardHeader>
              <p>{asset.code} - {asset.name}</p>
              <img src={asset.icon_url}/>
            </IonCardHeader>
            <IonCardContent>
            <p>Versions</p>
            <p>v001</p>
            <p>v002</p>
            <p>v003</p>
            </IonCardContent>
          </IonCard>
          <IonButton onClick={() => set_show_upload_modal(false)}>Close</IonButton>
        </IonModal>




        {/* Main Content */}
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <img src={asset.url}/>
          <IonCardHeader>
            <IonCardSubtitle>{asset.code}</IonCardSubtitle>
            <IonCardTitle>{asset.name}</IonCardTitle>
            <IonCardSubtitle>{asset.description}</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
          </IonCardContent>
        </IonCard>



      </IonContent>
    </IonPage>
    );
};

export default AssetDetail;
