import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonTextarea,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonAlert,
    IonList,
    IonItemDivider,
    IonGrid, IonRow, IonCol,

    useIonViewWillEnter,

} from '@ionic/react';

import React from 'react';
import { useState } from "react";
import { useParams } from 'react-router';

import './Page.css';

import { useForm, Controller } from "react-hook-form";

import { get_server } from '../components/Server';


//import ReactDom from 'react-dom';
//import { Form } from 'react-formio';

const JobNew: React.FC = () => {

    const { name } = useParams<{ name: string; }>();
    const { job_type_code } = useParams<{ job_type_code: string; }>();
    const { job_code } = useParams<{ job_code: string; }>();

    const [showAlert1, setShowAlert1] = useState(false);
    const [job_type, set_job_type] = useState<any>();
    //const [job, set_job] = useState<any>({code: ""});
    const [request_type, set_request_type] = useState<string>("");

    var job: any = { code: ""};
    //var job_data: any = {};
    const [job_data, set_job_data] = useState<any>();


    useIonViewWillEnter( () => {
        get_job_type_info();
    } );

    const get_job_type_info = async () => {

        let server = await get_server();

        if (job_code) {
            server.p_eval("@SOBJECT(workflow/job['code','"+job_code+"'])", {single:true})
            .then( (ret_val: any) => {
                job = ret_val;
                set_job_data(job.custom_data || {});
                if (job) {
                    let job_type_code = job.job_type;
                    return server.p_eval("@SOBJECT(workflow/job_type['code','"+job_type_code+"'])", {single: true})
                }
            } )
            .then( (ret_val: any) => {
                if (ret_val) {
                    set_job_type(ret_val);
                }
            } )


        }
        else {
            server.p_eval("@SOBJECT(workflow/job_type['code','"+job_type_code+"'])")
            .then( (job_types: any[]) => {
                set_job_type(job_types[0]);
            } )
        }
    }





    const { control, register, handleSubmit, errors, formState } = useForm();
    const [data, setData] = useState();
    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setData(data);

        let cmd = 'spt.modules.workflow.CreateJobCmd';
        let kwargs = {
            data: data
        }

        let server = await get_server();
        //let ret_val = server.execute_cmd( cmd, kwargs );
        //console.log(ret_val);
        server.p_execute_cmd( cmd, kwargs )
        .then( (ret_val: any) => {
            setShowAlert1(true);
        } )
        .catch( (e: any) => {
            alert(e);
        } );

    };




    const get_form_component = (component: any, level: number) => {

        let data = job_type.data;
        let conditions = data.conditions;

        let switch_name = "request";

        let show = false;
        if (component.key == switch_name) {
            show = true;
        }
        if (request_type) {
            let condition = conditions[request_type] || [];
            if (condition) {
                if (condition.indexOf(component.key) != -1) {
                    show = true;
                }

            }
        }

        let on_switch: any;
        if (component.key == switch_name) {
            on_switch = (e: any) => {
                set_request_type(e.detail.value);
            }
        }
        else {
            on_switch = (e: any) => {};
        }

        console.log("level: " +level);
        console.log(component);
        let columns: any[] = component.columns;
        let subcomponents: any[] = component.components;
        return (
          <>
            { show && component.type == "panel" &&
            <div className="spt_form_panel">
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{component.title}</IonCardTitle>
                <IonItemDivider/>
              </IonCardHeader>
              <IonCardContent>
               <div>
                {
                subcomponents.map( (subcomponent, index) => (
                <div key={index}>
                { get_form_component(subcomponent, level+1) }
                </div>
                ) )
                }
               </div>
              </IonCardContent>
            </IonCard>
            </div>
            }


            { show && component.type != "panel" && subcomponents && subcomponents.length &&
            <IonCard>
              <IonCardContent>
               <IonTitle>{component.title}</IonTitle>
               <div>
                {
                subcomponents.map( (subcomponent, index) => (
                <div key={index}>
                { get_form_component(subcomponent, level+1) }
                </div>
                ) )
                }
               </div>
              </IonCardContent>
            </IonCard>
            }




            { show && component.type == "columns" &&
            <IonGrid>
              <IonRow>
                  {
                  columns.map( (column, index) => (
                  <IonCol key={index} size={column.width}>
                  { get_form_component(column, level+1) }
                  </IonCol>
                  ) )
                  }
              </IonRow>
            </IonGrid>
            }

            { show && component.type == "textfield" &&
            <IonItem className="spt_textfield">
              <IonLabel position="floating">{component.label}</IonLabel>
              <IonInput name={component.key}
                value={job_data && job_data[component.key]}
                ref={register({required: true})}/>
            </IonItem>
            }

            { show && component.type == "textarea" &&
            <IonItem className="spt_textarea">
              <IonLabel position="floating">{component.label}</IonLabel>
              <IonTextarea rows={3} name={component.key}
                value={job_data && job_data[component.key]}
                ref={register({required: true})}/>
            </IonItem>
            }



            { show && component.type == "htmlelement" &&
            <IonItem>
                <p/>
                <p>{component.content}</p>
                <p/>
            </IonItem>
            }


            { show && component.type == "checkbox" &&
            <IonItem style={{"margin": "0px 20px 0px 0px"}}>
              <IonCheckbox style={{"margin": "0px 20px 0px 0px"}} name={component.key}/>
              <IonLabel color="dark" style={{"fontSize": "0.9rem", "whiteSpace": "break-spaces"}}>{component.label}</IonLabel>
            </IonItem>
            }

            {/* Select Widget */}
            { show && component.type == "select" &&
            <IonItem style={{"width": "100%", "margin": "0px 20px 0px 0px"}}>
              <IonLabel position="floating">{component.label}</IonLabel>
              <IonSelect placeholder="Select" style={{"width": "100%", "marginRight": "10px"}} name={component.key} onIonChange={on_switch}>
              {
                component.data.values.map( (option: any, index: number) => (
                    <IonSelectOption value={option.value}>{option.label}</IonSelectOption>
                ) )
              }
              </IonSelect>
            </IonItem>
            }


        </>
        )
    }


    const build_form = () => {
        if (!job_type) return (false)

        let data = job_type.data;
        let form_data_str = data.form_extra_data;
        if (form_data_str == "") {
            return (false)
        }

        //console.log(form_data_str);
        let form_data = JSON.parse(form_data_str);
        let components: any[] = form_data.components;


        return(
        <div>
          <div style={{"margin": "30px 30px", "fontWeight": "bold"}}>Please select a service request:</div>
          <input name="name" ref={register()} type="hidden" value={job_type.name}/>
          <input name="job_type" ref={register()} type="hidden" value={job_type.code}/>
          <input name="description" ref={register()} type="hidden" value=""/>

          <div style={{"margin": "30px 0px"}}>
          {
              components.map( (component, index) => (
              <>
              { get_form_component(component, 0) }
              </>
              ) )
          }
          </div>

        </div>
        )

    }



    return (

    <form onSubmit={handleSubmit(onSubmit)}>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create a new Request: {request_type}</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
          <IonButton slot="end" type="submit">Send Request</IonButton>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          cssClass='my-custom-class'
          header={'Request Received'}
          message={'Reference Code: '+ job.code}
          buttons={['OK']}
        />

        {job_type && build_form() ||

        <IonCard>
          <IonCardContent>
            <IonItem>
              <IonLabel position="stacked">Name</IonLabel>
              <IonInput name="name" ref={register({required: true})}/>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Description</IonLabel>
              <IonTextarea name="description" ref={register({required: true})}/>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Location</IonLabel>
              <IonInput name="location" ref={register({required: true})}/>
            </IonItem>



            <IonItem>
              <IonLabel>Feedback Required?</IonLabel>
              <IonCheckbox name="feedback" ref={register({required: true})}/>
            </IonItem>

            <IonItem>
              <IonLabel>Dangerous Situation?</IonLabel>
              <IonCheckbox name="dangerous" ref={register({required: true})}/>
            </IonItem>

          </IonCardContent>
        </IonCard>
        }

      </IonContent>
    </IonPage>
    </form>
    );
};

export default JobNew;
